// Breakpoints
$min-media-sizes: (
	"m": 375px,
	"t": 744px,
	'md': 1024px,
	"d": 1280px,
	"xl": 1440px,
	"xxl": 1600px,
	"xxxl": 1920px
);

$max-media-sizes: (
	"sMax": 374px,
	"mMax": 743px,
	"tMax": 1023px,
	"dMax": 1279px,
	"xlMax": 1439px
);

// Colors
$white: #fff;
$black: #000;
$red: #DA2925;
$blue: #2684ff;
$color-1: #2e2e2e;
$color-2: #4ec1e0;
$color-3: #7f8999;
$color-4: #b2b2b2;

// Values

