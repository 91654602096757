.footer {
    background-color: $color-1;
    height: rem(94);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include min-media('t') {
        height: rem(120);
    }

    &__text {
        display: block;
        color: $color-4;
        font: 400 rem(14)/rem(19) 'Rubik', sans-serif;

        @include min-media('t') {
            font-size: rem(20);
            line-height: rem(28);
        }
    }
}
