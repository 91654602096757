@import './styles/variables.scss';
@import './styles/function.scss';
@import './styles/media.scss';
@import './components/footer/index.scss';
@import './styles/fonts.scss';

.matrix-calculator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(100% - #{rem(46)});
    margin: rem(45) auto rem(44);
    width: 100%;
    overflow: hidden;

    @include max-media('sMax') {
        max-width: calc(100% - #{rem(20)});
    }

    @include min-media('t') {
        margin: rem(50) auto rem(64);
        max-width: calc(100% - #{rem(89)});
    }

    @include min-media('md') {
        margin: rem(50) auto rem(118);
        max-width: rem(950);
    }

    &__title {
        font: 400 rem(20)/rem(23) 'Rubik', sans-serif;
        color: $color-1;
        display: block;
        margin-bottom: rem(30);
        margin-top: 0;

        @include min-media('t') {
            margin-bottom: rem(29);
            font-size: rem(24);
            line-height: rem(28);
        }

        @include min-media('md') {
            margin-bottom: rem(51);
        }
    }

    &__fields,
    &__date {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include min-media('t') {
            flex-direction: row;
        }
    }

    &__field {
        margin-bottom: rem(17);
        font-size: rem(18);
        line-height: rem(21);

        @include max-media('mMax') {
            width: rem(152);
        }

        @include min-media('t') {
            margin-right: rem(25);
            margin-bottom: 0;
            font-size: rem(24);
            line-height: rem(28);
        }

        &.error > [class*=control]{
            border-color: $red;
            box-shadow: 0 0 0 1px $red;
            border-radius: rem(3);
        }
    }

    &__input {
        border-radius: rem(3);
        border: 1px solid $color-2;
        padding: rem(9) rem(15) rem(5);
        color: hsl(0, 0%, 20%);
        height: rem(48);
        max-width: rem(90);
        transition: box-shadow 0.3s ease, border 0.3s ease;
        -moz-appearance: textfield;

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &.error {
            border-color: $red;
            box-shadow: 0 0 0 1px $red;
        }

        &:hover {
            border-color: hsl(0, 0%, 70%);
            box-shadow: 0 0 0 1px rgba($red, 0);
        }

        &:focus {
            border-color: $blue;
            box-shadow: 0 0 0 1px $blue;
        }

        @include max-media('mMax') {
            max-width: rem(152);
            width: rem(152);
            padding-left: rem(40);
        }
    }

    &__date {
        margin-bottom: rem(19);

        @include min-media('t') {
            margin-bottom: rem(39);
            flex-direction: column;
        }

        @include min-media('md') {
            margin-bottom: rem(69);
            flex-direction: row;
        }
    }

    &__button {
        background-color: $color-2;
        color: $white;
        padding: rem(9) rem(25);
        border-radius: rem(3);
        height: rem(48);
        font: 400 rem(18)/rem(21) 'Rubik', sans-serif;

        @include max-media('mMax') {
            width: rem(152);
        }

        @include min-media('t') {
            font-size: rem(24);
            line-height: rem(28);
            padding: rem(10) rem(30);
            margin-top: rem(29);
        }

        @include min-media('md') {
            margin-left: rem(90);
            margin-top: 0;
        }
    }

    &__diagramma {
        width: rem(323);
        height: rem(323);
        margin: rem(19) auto rem(55);
        max-width: 100%;

        @include min-media('t') {
            width: rem(501);
            height: rem(501);
            margin: 0 auto rem(73);
        }

        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    &__table {
        border-collapse: separate;
        border-spacing: 2px 0;
        width: 100%;

        th,
        td {
            padding: rem(16);
            width: 25%;
            font: 400 rem(13)/rem(15) 'Rubik', sans-serif;
            color: $black;
            text-align: center;

            @include max-media('sMax') {
                padding: rem(16) 0;
                width: 10%;

                &:last-child {
                    width: 70%;
                }
            }

            @include min-media('t') {
                font-size: rem(24);
                line-height: rem(28);
            }

            @include min-media('md') {
                height: rem(59);
                padding: rem(15) rem(16);
            }
        }

        tr:nth-child(2),
        tr:nth-child(2) + tr {
            td {
                color: $white;
            }
        }

        th,
        tr:first-child td {
            border-top: 2px solid #E4E8F0;
            padding: rem(5) rem(16);

            @include max-media('sMax') {
                padding: rem(5) rem(12);
            }

            @include min-media('t') {
                padding: rem(2) rem(16);
            }

            @include min-media('md') {
                padding: rem(16);
            }
        }

        tr:last-child td,
        th,
        td {
            &:first-child {
                border-left: 2px solid #E4E8F0;
            }

            &:last-child {
                border-right: 2px solid #E4E8F0;
            }
        }
    }
}
