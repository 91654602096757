html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Rubik', sans-serif;
}

code {
  font-family: 'Rubik', monospace;
}

a {
  color: inherit;
}

button,
input,
textarea,
select {
    background: none;
    font-family: 'Rubik', sans-serif;
    border: 0;
    border-radius: 0;
    outline: 0;
    transition: border 0.3s ease, color 0.3s ease;
    cursor: pointer;
    padding: 0;
}

h1, h2, p {
  margin: 0;
}

svg {
  transition: fill 0.3s ease, stroke 0.3s ease;
}

* {
  box-sizing: border-box;
}

#root {
    position: relative;
}
